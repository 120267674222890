.plus-minus {
  display: flex;
  //justify-content: space-between;

  &.input-group {
    flex-wrap: nowrap;
  }

  .input-number {
    min-width: 40px;
    max-width: 80px;
  }

  label {
    float: left;
    line-height: 30px;
  }
}

.no-zoom {
  font-size: 16px;
  transform-origin: top left;
  transform: scale(0.75);    //  12px / 16px

  //  size up
  width:  240px;             // 180px / 0.75
  height: 32px;              //  24px / 0.75
  padding      : 5.333333px; //   4px / 0.75
  border-radius: 6.666667px; //   5px / 0.75
  border-width : 1.333333px; //   1px / 0.75

  //  size down
  margin-right: -60px;       // 240px * 0.25
  margin-bottom: -8px;       //  32px * 0.25
}

//input[type='text']
//input[type='number'],
//textarea {
//  font-size: 16px;
//}
